import React from "react";
import Laoder from "../Atoms/Laoder";
import { Postdelete } from "../helper/Http";
import { toast } from "sonner";

const DeleteRequest = () => {
  const [loading, setLoading] = React.useState(false);
  const [number1, setNumber1] = React.useState("");
  const number = "+994";
  const mobileNumber = "994" + number1;
  const HandleChange = (e) => {
    setNumber1(e.target.value);
  };
  const HandleSubmit = () => {
    setLoading(true);
    Postdelete({
      data: {
        mobile: mobileNumber,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res?.data?.success
            ? toast.success(res?.data?.message)
            : toast.error(res?.data?.message);

          setLoading(true);
        } else if (res.status === 400) {
          toast.error("Sistemdə xəta baş verdi");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full h-screen flex justify-center items-center ">
      <div className="w-full max-w-[600px]   bg-white p-4 rounded-lg shadow-lg">
        <div className="max-w-[420px] mx-auto w-full">
          <h1 className="text-3xl font-bold text-center">
            Confirm Your Account Deletion
          </h1>
          <p className="text-center mt-4 text-sm">
            We're sorry to see you go. If you decide to delete your account,
            please be aware that this process is irreversible. Once you delete
            your account, all your data will be permanently removed from our
            system.
          </p>

          <div className="pt-4  flex flex-col gap-1 items-start justify-start shrink-0 w-full ">
            <div className="bg-background-color rounded-lg border-solid border-background-color border pt-2.5 pr-4 pb-2.5 pl-4 flex flex-row gap-1 items-center justify-start self-stretch shrink-0 relative">
              <div>{number}</div>
              <input
                maxLength={9}
                type="text"
                className="
              outline-none
            
              text-grey-color text-left font-regular-16-24-font-family text-regular-16-24-font-size leading-regular-16-24-line-height font-regular-16-24-font-weight relative flex-1 flex items-center justify-start"
                value={number1}
                onChange={HandleChange}
              />
            </div>
          </div>
          <div
            className="bg-green-400  mt-4 w-full  text-white rounded-[10px] pt-2.5 pr-4 pb-2.5 pl-4 flex flex-row gap-3 items-center justify-center shrink-0 "
            style={{
              boxShadow:
                "0px 0px 4px 0px rgba(20, 23, 26, 0.04),  0px 0px 8px 0px rgba(20, 23, 26, 0.08)",
            }}
          >
            <button
              disabled={loading}
              onClick={HandleSubmit}
              className="text-white-color w-full z-50 disabled:cursor-not-allowed flex items-center justify-center gap-3 text-center font-medium-14-24-font-family text-medium-14-24-font-size leading-medium-14-24-line-height font-medium-14-24-font-weight relative"
            >
              {loading ? <Laoder /> : "Qeydiyyat ol "}
            </button>
          </div>
          <p className="text-sm font-thin mt-3 leading-5 text-slate-700">
            By clicking on "Confirm Deletion," you acknowledge that you
            understand that this action cannot be undone and all your data will
            be permanently erased. If you have any questions or concerns, or if
            you need assistance with this process, please contact our support
            team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeleteRequest;
