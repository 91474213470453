import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import "./scss/style.scss";
import { Toaster } from "sonner";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster richColors position="top-right" />
    <App />
  </React.StrictMode>
);
