import React from "react";
import { RotatingLines } from "react-loader-spinner";

const Laoder = () => {
  return (
    <RotatingLines
      visible={true}
      height="20"
      width="20"
      color="#fff"
      strokeWidth="4"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      strokeColor="#fff"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

export default Laoder;
