import React from "react";

const PartnersCardSkeleton = ({ number }) => {
    return (
        Array(number).fill(0).map((_, index) => (<div className="flex max-w-[32.8%] max-sm:max-w-full w-full px-4 py-2 rounded-xl flex-col bg-[#fafafa] animate-pulse">
            <div className="flex items-center justify-between w-full min-h-[120px]">
                <div className="w-[125px] h-[65px] bg-[#ececec] rounded-md"></div>

                <div className="flex-1 space-y-3 ml-4">
                    <div className="h-4 bg-[#ececec] rounded-md w-3/4"></div>
                    <div className="h-4 bg-[#ececec] rounded-md w-1/2"></div>
                    <div className="h-3 bg-[#ececec] rounded-md w-1/4 mt-1"></div>
                </div>

                <div className="h-8 w-8 bg-[#ececec] rounded-md"></div>
            </div>
        </div>

        )
        )
    );
};

export default PartnersCardSkeleton;
